import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import axios from 'axios'
import { getBusinessHeader, getImageHeader } from '../../config/header'
import { useFormik } from 'formik'
import * as Yup from 'yup'
// import 'react-toastify/dist/ReactToastify.css';
import Button from 'react-bootstrap/Button';
import useRazorpay from 'react-razorpay'
import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

import { useDispatch, useSelector } from 'react-redux'
import { FormGroup, Input, Label } from 'reactstrap'
import { setUser,setAuthenticate} from '../../store/slices/user'
import { useNavigate } from 'react-router-dom'
import { IoMdArrowRoundBack } from "react-icons/io";
import { Alert, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from 'react-bootstrap'
import Sidebar from '../Sidebar'
import { Box, useMediaQuery } from '@mui/material'


// const validationSchema = ;


const ApplicationForm = forwardRef(({ setActiveTab }, ref)  => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [proceedPay, setProceedPay] = useState(true);
    const [hidePayBtn, setHidePayBtn] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const applicationAmount = '500';
    const [showSuccessModal,setShowSuccessModal]=useState(false)
    const userSelector = useSelector(state => state.user.user)
    // const ApplicaionData = sessionStorage.getItem("ApplicaionData") !== undefined && JSON.parse(sessionStorage.getItem("ApplicaionData"));
    const [user, setUserDetail] = useState(null)
    const [file, setFile] = useState(null);
    const [uploadedImageUrl, setUploadedImageUrl] = useState(null);
    const customerToken = sessionStorage.getItem("customerToken")

    useEffect(() => {
        setUserDetail(userSelector);
        if (userSelector && userSelector.applicationImage && userSelector.applicationImage.image_url) {
            setUploadedImageUrl(userSelector.applicationImage.image_url.split('?')[0]);
        }
    }, [userSelector]);

    useEffect(() => {
        if (user?.is_application_paid || (user?.hasOwnProperty('razorpayDetails') && user?.razorpayDetails)) {
            setHidePayBtn(true)
        }
    }, [user])

    const [application, setApplication] = useState(null)
    const [razorpayDetails, setRazorpayDetails] = useState(null)
    const [Razorpay] = useRazorpay()


    const formik = useFormik({
        initialValues: {
            name: (user && user?.name) || "",
            email: (user && user?.email) || "",
            phone: (user && user?.phone) || "",
            gender: (user && user?.gender) || "Male",
            dob: (user && user?.dob) || "",
            community: "General",
            nationality: (user && user?.nationality) || "Indian",
            // aadhar: user && user?.aadhar || "",
            guardian: (user && user?.guardian) || "",
            address: (user && user?.address) || "",
            hostelRequired: (user && user?.hostelRequired) || "No",
            transportationRequired: (user && user?.transportationRequired) || "No",
            ug: (user && user.degree && user.course && ((user.degree_type)?.toLowerCase() === 'ug')) ? (user.degree + '. ' + user.course) : (user && user?.ug) ? user?.ug : 'NA',
            pg: (user && user.degree && user.course && !((user.degree_type)?.toLowerCase() === 'ug')) ? (user.degree + '. ' + user.course) : (user && user?.pg) ? user?.pg : 'NA',
            hss: "",
            hsPassing: "",
            hsPercentage: "",
            sslc: "",
            sslcPassing: "",
            sslcPercentage: "",
            agreeToApplicationInfo: false,
            is_application_paid: false,
            is_application_saved: false,
            submit: null,
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Full Name is required'),
            email: Yup.string().email('Invalid email address').required('Email is required'),
            phone: Yup.string().required('Phone number is required'),
            gender: Yup.string().required('Gender is required'),
            dob: Yup.date().required('Date of Birth is required'),
            community: Yup.string().required('Community is required'),
            nationality: Yup.string().required('Nationality is required'),
            // aadhar: Yup.string().required('Aadhar Number is required'),
            guardian: Yup.string().required('Guardian/Parent Name is required'),
            address: Yup.string().required('Permanent Address is required'),
            hostelRequired: Yup.string().required('Hostel Required field is required'),
            transportationRequired: Yup.string().required('Transportation Required field is required'),
            ug: Yup.lazy(() => {
                if (formik.values.pg) {
                    return Yup.string()
                }
                return Yup.string().required("UG selection is required")
            }),
            pg: Yup.lazy(() => {
                if (formik.values.ug) {
                    return Yup.string()
                }
                return Yup.string().required("PG selection is required")
            }),
            hss: Yup.string().required('HSC/ Polytechnic Name is required'),
            hsPassing: Yup.string().required('Year of Passing (HSC) is required'),
            hsPercentage: Yup.string(),
            sslc: Yup.string().required('SSLC School Name is required'),
            sslcPassing: Yup.string().required('Year of Passing (SSLC) is required'),
            sslcPercentage: Yup.string(),
            agreeToApplicationInfo: Yup.boolean().oneOf([true], 'Please agree to save application'),
        }),
        onSubmit: async (values, helpers) => {
            if (!file) {
                toast.error('Please select a file to upload.', { position: 'top-right' });
                return;
            }
            try {
                const customerToken = sessionStorage.getItem("customerToken");
                const res = await axios.get(`/business/api/v1/storage/signedurlput?file_folder=admissionPayment&file_extension=${file.type.split('/')[1]}`, getBusinessHeader(customerToken));
                const fileKey = res.data.filekey;
                const signedURLPut = res.data.signed_url_put;
        
                await fetch(signedURLPut, {
                    method: 'PUT',
                    headers: getImageHeader(),
                    body: file
                });
        
                const baseUrl = signedURLPut.split('?')[0];
                const data = {
                    ...values,
                    twelfthMarksheet: {
                        image_filekey: fileKey,
                        image_url: baseUrl
                    },
                    is_application_saved:true,
                    degree_type: user && user.degree_type
                }
                if (values.agreeToApplicationInfo === true) {
                    const URL = user?.application_id ? `/business/api/v1/application/${user.application_id}` : "/business/api/v1/application"
                    const applicationResponse = user?.application_id ? await axios.put(URL, data, getBusinessHeader(`${customerToken}`)) : await axios.post(URL, data, getBusinessHeader(`${customerToken}`));
                    if (applicationResponse) {
                        // toast.success("Application submitted successfully..!", { position: "top-right" });
                        setProceedPay(false);
                        setApplication(applicationResponse.data);
                        formik.resetForm();
                        dispatch(setUser(applicationResponse.data))
                        setUploadedImageUrl(baseUrl);
                        setShowSuccessModal(true); 
                    }
                } else {
                    toast.error('Please check all required fields are filled', { position: 'top-right' })
                    helpers.setSubmitting(false)
                }
            } catch (err) {
                setProceedPay(false)
                toast.error(err.response && err.response.data ? err.response.data.ErrorDetail : "Application submitted failed..!", { position: "top-right" })
                console.err('err submitting form:', err);
                helpers.setSubmitting(false)
            }
        },
    });


    useEffect(() => {
        formik.setValues({
            name: (user && user?.name) || "",
            email: (user && user?.email) || "",
            phone: (user && user?.phone) || "",
            gender: (user && user?.gender) || "Male",
            dob: (user && user?.dob) || "",
            community: (user && user?.community) || "General",
            nationality: (user && user?.nationality) || "Indian",
            aadhar: (user && user?.aadhar) || "",
            guardian: (user && user?.guardian) || "",
            address: (user && user?.address) || "",
            hostelRequired: (user && user?.hostelRequired) || "No",
            transportationRequired: (user && user?.transportationRequired) || "No",
            ug: (user && user.degree && user.course && ((user.degree_type)?.toLowerCase() === 'ug')) ? (user.degree + '. ' + user.course) : (user && user?.ug) ? user?.ug : 'NA',
            pg: (user && user.degree && user.course && !((user.degree_type)?.toLowerCase() === 'ug')) ? (user.degree + '. ' + user.course) : (user && user?.pg) ? user?.pg : 'NA',
            hss: (user && user?.hss) || "",
            hsPassing: (user && user?.hsPassing) || "",
            hsPercentage: (user && user?.hsPercentage) || "",
            sslc: (user && user?.sslc) || "",
            sslcPassing: (user && user?.sslcPassing) || "",
            sslcPercentage: (user && user?.sslcPercentage) || "",
            is_application_paid: (user && user?.is_application_paid) || false,
            is_application_saved: (user && user?.is_application_saved) || false,
            agreeToApplicationInfo: (user && user?.agreeToApplicationInfo) || false,
        })
        formik.setTouched(formik.values, false)
        if (user?.application_id) {
            setProceedPay(false)
        }
    }, [user])

    const handlePayment = async () => {
        try {
            let bodyData = new FormData();
            bodyData.append("grant_type", 'client_credentials');
            bodyData.append("client_id", process.env.REACT_APP_CLIENT_ID);
            bodyData.append("client_secret", process.env.REACT_APP_CLIENT_SECRET);
    
            const tokenResponse = await axios.post('/business/api/v1/auth/token', bodyData);
            const customerToken = tokenResponse.data.access_token;
    
            const data = {
                payment_id: process.env.REACT_APP_PAYMENT_ID,
                amount: `${applicationAmount * 100}`
            };
    
            const response = await axios.post('/business/api/v1/sales/payments/razorpay', data, getBusinessHeader(customerToken));
    
            const options = {
                key: response.data.key_id,
                amount: response.data.amount,
                currency: response.data.currency,
                name: "Vels University",
                description: "Application Purchase Transaction",
                order_id: response.data.id,
                handler: function (response) {
                    setRazorpayDetails(response);
                }
            };
    
            const rzp1 = new Razorpay(options);
    
            rzp1.on("payment.failed", function (response) {
                alert(response.error.code);
                alert(response.error.description);
                alert(response.error.source);
                alert(response.error.step);
                alert(response.error.reason);
                alert(response.error.metadata.order_id);
                alert(response.error.metadata.payment_id);
            });
    
            rzp1.open();
        } catch (error) {
            if (error.response && error.response.status === 412) {
                toast.error('Error in payment settings please contact administrator.', { position: 'top-right' });
            } else {
                toast.error('An error occurred during payment initiation. Please try again.', { position: 'top-right' });
            }
            console.error('Error initiating payment:', error);
        }
    };

    const updateApplication = async () => {
        try {
            let bodyData = new FormData()
            bodyData.append("grant_type", 'client_credentials')
            bodyData.append("client_id", process.env.REACT_APP_CLIENT_ID)
            bodyData.append("client_secret", process.env.REACT_APP_CLIENT_SECRET)
            const tokenResponse = await axios.post('/business/api/v1/auth/token', bodyData);
            const customerToken = tokenResponse.data.access_token;

            const updateData = {
                razorpayDetails: razorpayDetails,
                is_application_paid: true,
                txn_date: new Date(),
                txn_amount: applicationAmount
            }
            const applicationId = application ? application.application_id : user && user.application_id
            const applicationUpdateResponse = await axios.put(`/business/api/v1/application/${applicationId}`, updateData, getBusinessHeader(`${customerToken}`));
            if (applicationUpdateResponse) {
                formik.resetForm();
                sessionStorage.setItem("applicationId", applicationId)
                dispatch(setUser(applicationUpdateResponse.data))
                toast.success('Payment successfully completed and Application updated !', { position: 'top-right' });
                setHidePayBtn(true);
                setProceedPay(true)
                navigate('/payment-success', { replace: true })
                dispatch(setAuthenticate(true));

            }
        } catch (err) {
            console.log("err", err)
            toast.error('Payment failed..!', { position: 'top-right' });
        }
    }

    useEffect(() => {
        if (razorpayDetails) {
            updateApplication();
        }
    }, [razorpayDetails]);
    const handleBackClick = () => {
        setActiveTab(1); 
    }
    useImperativeHandle(ref, () => ({
        handlePayment
    }));
    const isMobile = useMediaQuery('(max-width: 768px)');
   
const handleFileChange=(e)=>{
    const selectedFile=e.target.files[0]
    setFile(selectedFile)
    setUploadedImageUrl(URL.createObjectURL(selectedFile));
}
    return (
        <>

    <div className={`w-${isMobile ? '12/12' : '11/12'} mx-${isMobile?'2':'5'} border-1 rounded-md !border-richblack-200 px-4 py-4`} style={{height:isMobile?'2700px':''}}>    
            <div className='rounded-sm md:px-5 md:py-5 px-3 py-2'>
            <div className='flex gap-5 items-center'>
                        <button type="button" onClick={handleBackClick} className="text-blue-500 hover:underline">
                            <IoMdArrowRoundBack size={24} />
                        </button>
                        <h1 className='text-xl font-bold'>Application Form</h1>
                    </div>
                <h3 className='text-center text-4xl'>Admission Form</h3>
                <form className='ApplicationForm' onSubmit={formik.handleSubmit} noValidate>
                    <FormGroup>
                        <Label className='pb-2'> Full Name <span className='text-pink-800 font-bold'>*</span> </Label>
                        <Input
                            className='w-full outline-none border border-richblack-800 h-[40px] px-3'
                            type='text'
                            name='name' id='name'
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            placeholder='Enter Your Full Name'
                        />
                        <span className='error-msg'>{formik.errors && formik.touched && formik.errors.name}</span>
                    </FormGroup>

                    <FormGroup>
                        <Label className='pb-2'> Email <span className='text-pink-800 font-bold'>*</span> </Label>
                        <Input
                            className='w-full outline-none border border-richblack-800 h-[40px] px-3'
                            type='email'
                            name='email' id='email'
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            placeholder='Email'
                        />
                        <span className='error-msg'>{formik.errors && formik.touched && formik.errors.email}</span>
                    </FormGroup>

                    <FormGroup>
                        <Label className='pb-2'> Phone <span className='text-pink-800 font-bold'>*</span> </Label>
                        <Input
                            className='w-full outline-none border border-richblack-800 h-[40px] px-3'
                            type='phone'
                            name='phone' id='phone'
                            value={formik.values.phone}
                            onChange={formik.handleChange}
                            placeholder='Phone'
                        />
                        <span className='error-msg'>{formik.errors && formik.touched && formik.errors.phone}</span>
                    </FormGroup>

                    <div className='pt-3 gap-3 flex flex-col md:flex-row'>
                        <FormGroup className='md:w-[50%] w-full'>
                            <Label className='pb-2'> Gender <span className='text-pink-800 font-bold'>*</span> </Label>
                            <Input
                                className='w-full outline-none border border-richblack-800 h-[40px] px-3'
                                type='select'
                                name='gender' id='gender'
                                value={formik.values.gender}
                                onChange={formik.handleChange}
                            >
                                <option value={"Male"} >Male</option>
                                <option value={"Female"}>Female</option>
                                <option value={"Third Gender"}>Third Gender</option>
                            </Input>
                            <span className='error-msg'>{formik.errors && formik.touched && formik.errors.gender}</span>
                        </FormGroup>

                        <FormGroup className='md:w-[50%] w-full'>
                            <Label className='pb-2'> D.O.B <span className='text-pink-800 font-bold'>*</span> </Label>
                            <Input
                                className='w-full outline-none border border-richblack-800 h-[40px] px-3'
                                type='date'
                                name='dob' id='dob'
                                value={formik.values.dob}
                                onChange={formik.handleChange}
                                min={new Date(new Date().getFullYear() - 30, new Date().getMonth(), new Date().getDate()).toISOString().split("T")[0]}
                                max={new Date(new Date().getFullYear() - 16, new Date().getMonth(), new Date().getDate()).toISOString().split("T")[0]}
                            />
                            <span className='error-msg'>{formik.errors && formik.touched && formik.errors.dob}</span>
                        </FormGroup>
                    </div>
                    <div className='pt-3 gap-3 flex flex-col md:flex-row'>
                        <FormGroup className='md:w-[50%] w-full'>
                            <Label className='pb-2'> Community <span className='text-pink-800 font-bold'>*</span> </Label>
                            <Input
                                className='w-full outline-none border border-richblack-800 h-[40px] px-3'
                                type='select'
                                name='community' id='community'
                                value={formik.values.community}
                                onChange={formik.handleChange}
                            >
                                <option value={"General"}>General</option>
                                <option value={"BC"}>BC</option>
                                <option value={"MBC"}>MBC</option>
                                <option value={"SC/ST"}>SC / ST</option>
                            </Input>
                            <span className='error-msg'>{formik.errors && formik.touched && formik.errors.community}</span>
                        </FormGroup>

                        <FormGroup className='md:w-[50%] w-full'>
                            <Label className='pb-2'> Nationality <span className='text-pink-800 font-bold'>*</span> </Label>
                            <Input
                                className='w-full outline-none border border-richblack-800 h-[40px] px-3'
                                type='text'
                                name='nationality' id='nationality'
                                value={formik.values.nationality}
                                disabled
                                placeholder='Nationality'
                                onChange={formik.handleChange}
                            />
                        </FormGroup>
                    </div>

                    <FormGroup>
                        <Label className='pb-2'> Guardian/Parent Name <span className='text-pink-800 font-bold'>*</span> </Label>
                        <Input
                            className='w-full outline-none border border-richblack-800 h-[40px] px-3'
                            type='text'
                            name='guardian' id='guardian'
                            value={formik.values.guardian}
                            onChange={formik.handleChange}
                            placeholder='Guardian/Parent Name'
                        />
                        <span className='error-msg'>{formik.errors && formik.touched && formik.errors.guardian}</span>
                    </FormGroup>

                    <FormGroup>
                        <Label className='pb-2'> Permanent Address <span className='text-pink-800 font-bold'>*</span> </Label>
                        <Input
                            className='w-full outline-none border border-richblack-800 h-[40px] px-3'
                            type='text'
                            name='address' id='address'
                            value={formik.values.address}
                            onChange={formik.handleChange}
                            placeholder='Enter Your Address'
                        />
                        <span className='error-msg'>{formik.errors && formik.touched && formik.errors.address}</span>
                    </FormGroup>

                    <div className='pt-3 flex flex-col'>
                        <label className='pb-2'>Transportation Required<span className='text-pink-800 font-bold'>*</span></label>
                        <div className='flex flex-row gap-3 items-center'>
                            <div className='flex flex-row items-center justify-center'>Yes{" "}<input value={"Yes"} checked={formik.values.transportationRequired === 'Yes'} onChange={(e) => formik.setFieldValue('transportationRequired', e.target.checked ? 'Yes' : 'No')} name='transportationRequired' id='transportationRequired' type='radio' /></div>
                            <div className='flex flex-row items-center justify-center'>No{" "}<input value={"No"} checked={formik.values.transportationRequired === 'No'} onChange={(e) => formik.setFieldValue('transportationRequired', e.target.checked ? 'No' : 'Yes')} name='transportationRequired' id='transportationRequired' type='radio' /></div>
                        </div>
                        <span className='error-msg'>{formik.errors && formik.touched && formik.errors.transportationRequired}</span>
                    </div>

                    <div className='pt-3 flex flex-col'>
                        <label className='pb-2'>Hostel Required<span className='text-pink-800 font-bold'>*</span></label>
                        <div className='flex flex-row gap-3 items-center'>
                            <div className='flex flex-row items-center justify-center'>Yes{" "}<input value={"Yes"} checked={formik.values.hostelRequired === 'Yes'} onChange={(e) => formik.setFieldValue('hostelRequired', e.target.checked ? 'Yes' : 'No')} name='hostelRequired' id='hostelRequired' type='radio' /></div>
                            <div className='flex flex-row items-center justify-center'>No{" "}<input value={"No"} checked={formik.values.hostelRequired === 'No'} onChange={(e) => formik.setFieldValue('hostelRequired', e.target.checked ? 'No' : 'Yes')} name='hostelRequired' id='hostelRequired' type='radio' /></div>
                        </div>
                        <span className='error-msg'>{formik.errors && formik.touched && formik.errors.hostelRequired}</span>
                    </div>

                    <div className='w-full pt-3'>
                        <label className='pb-2'>UG COURSES<span className='text-pink-800 font-bold'>*</span></label>
                        <Input
                            className='w-full outline-none border border-richblack-800 h-[40px] px-3'
                            type='text'
                            name='ug' id='ug'
                            disabled
                            value={formik.values.ug}
                            onChange={formik.handleChange}
                            placeholder='Enter Your Course'
                        />
                    </div>

                    <div className='w-full pt-3'>
                        <label className='pb-2'>PG COURSES<span className='text-pink-800 font-bold'>*</span></label>
                        <Input
                            className='w-full outline-none border border-richblack-800 h-[40px] px-3'
                            type='text'
                            name='pg' id='pg'
                            disabled
                            value={formik.values.pg}
                            onChange={formik.handleChange}
                            placeholder='Enter Your Course'
                        />
                    </div>

                    <div className='text-2xl font-bold py-3 pt-4'>ACADEMIC PROFILE</div>
                    <FormGroup>
                        <Label className='pb-2'> HSC/ Polytechnic Name <span className='text-pink-800 font-bold'>*</span> </Label>
                        <Input
                            className='w-full outline-none border border-richblack-800 h-[40px] px-3'
                            type='tel'
                            name='hss' id='hss'
                            value={formik.values.hss}
                            onChange={formik.handleChange}
                            placeholder='HSC/ Polytechnic Name'
                        />
                        <span className='error-msg'>{formik.errors && formik.touched && formik.errors.hss}</span>
                    </FormGroup>
                    <FormGroup>
                        <Label className='pb-2'> Year of Passing <span className='text-pink-800 font-bold'>*</span> </Label>
                        <Input
                            className='w-full outline-none border border-richblack-800 h-[40px] px-3'
                            type='select'
                            name='hsPassing' id='hsPassing'
                            value={formik.values.hsPassing}
                            onChange={formik.handleChange}
                        >
                            <option>Year </option>
                            {Array.from({ length: 5 }).map((_, index) => {
                                const year = new Date().getFullYear() - index;
                                return <option key={year} value={year}>{year}</option>;
                            })}
                        </Input>
                        <span className='error-msg'>{formik.errors && formik.touched && formik.errors.hsPassing}</span>
                    </FormGroup>
                    <FormGroup>
                        <Label className='pb-2'> Percentage/CGPA  </Label>
                        <Input
                            className='w-full outline-none border border-richblack-800 h-[40px] px-3'
                            type='tel'
                            name='hsPercentage' id='hsPercentage'
                            value={formik.values.hsPercentage}
                            onChange={formik.handleChange}
                            placeholder='Percentage/CGPA'
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label className='pb-2'> Upload 12th marksheet</Label>
                        <Input
                            className='w-full outline-none border border-richblack-800 h-[40px] px-3'
                            type='file'
                            value={formik.values.twelfthMarksheet}
                            onChange={handleFileChange}
                          
                        />
                    </FormGroup>
                    <FormGroup className='pt-3'>
                        <Label className='pb-2'> SSLC School Name <span className='text-pink-800 font-bold'>*</span> </Label>
                        <Input
                            className='w-full outline-none border border-richblack-800 h-[40px] px-3'
                            type='tel'
                            name='sslc' id='sslc'
                            value={formik.values.sslc}
                            onChange={formik.handleChange}
                            placeholder='SSLC School Name'
                        />
                        <span className='error-msg'>{formik.errors && formik.touched && formik.errors.sslc}</span>
                    </FormGroup>
                    <FormGroup>
                        <Label className='pb-2'> Year of Passing <span className='text-pink-800 font-bold'>*</span> </Label>
                        <Input
                            className='w-full outline-none border border-richblack-800 h-[40px] px-3'
                            type='select'
                            name='sslcPassing' id='sslcPassing'
                            value={formik.values.sslcPassing}
                            onChange={formik.handleChange}
                        >
                            <option>Year </option>
                            {Array.from({ length: 5 }).map((_, index) => {
                                const year = new Date().getFullYear() - index;
                                return <option key={year} value={year}>{year}</option>;
                            })}
                        </Input>
                        <span className='error-msg'>{formik.errors && formik.touched && formik.errors.sslcPassing}</span>
                    </FormGroup>
                    <FormGroup>
                        <Label className='pb-2'> Percentage/CGPA  </Label>
                        <Input
                            className='w-full outline-none border border-richblack-800 h-[40px] px-3'
                            type='tel'
                            name='sslcPercentage' id='sslcPercentage'
                            value={formik.values.sslcPercentage}
                            onChange={formik.handleChange}
                            placeholder='Percentage/CGPA'
                        />
                    </FormGroup>
                    <div className='w-full h-[40px] pt-3 px-1 mb-5 ml-4 outline-0 flex flex-row '>
                        <FormGroup>
                            <Input
                                type='checkbox'
                                name='agreeToApplicationInfo'
                                checked={formik.values.agreeToApplicationInfo}
                                onChange={formik.handleChange}
                            />
                            <Label className='ml-3'>
                                <span className='text-pink-800 font-bold'> * </span> Please verify the details throughly before submitting the application. By submitting your application, you confirm that all the details you've provided are true and complete.
                            </Label>
                        </FormGroup>

                    </div>
                    <span className='error-msg'>{formik.errors && formik.touched && formik.errors.agreeToApplicationInfo}</span> <br />
                    <Box
                    sx={{
                        pt: 4,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: { xs: 'column', sm: 'row' },
                        gap: 2,
                        marginTop:{xs:'100px',sm:'-50px'}
                    }}
                >
                    <Button
                        className={`bg-pure-greys-300 text-richblack-5 text-[20px] !px-4 !py-2 rounded-md border-none`}
                        type='submit'
                        disabled={!formik.isValid || formik.isSubmitting}
                    >
                        Submit
                    </Button>
                    {!hidePayBtn ? (
                        <Button
                            className="bg-blue-400 text-richblack-5 text-[20px] !px-4 !py-2 rounded-md border-none"
                            type='button'
                            disabled={proceedPay}
                            onClick={handlePayment}
                        >
                            Proceed to pay
                        </Button>
                    ) : (
                        <label className='pb-2'>Payment Completed</label>
                    )}
                </Box>
                    <Modal
                        show={showAlert}
                        onHide={() => setShowAlert(false)}
                        backdrop="static"
                        keyboard={false}
                    >
                        <ModalBody className='text-center py-3'>
                            Are you sure you want to leave without paying? <br />
                            <div className="mt-2"> {/* Add some margin-top for spacing */}
                                <Button className='' variant="secondary" onClick={() => setShowAlert(false)}>
                                    Close
                                </Button>
                                <span className="ml-2"></span> {/* Add some margin-left for spacing */}
                                <Button variant="primary" onClick={() => navigate("/")}>
                                    Yes
                                </Button>
                            </div>
                        </ModalBody>
                    </Modal>
                    
                    <Modal show={showSuccessModal} onHide={() => setShowSuccessModal(false)} centered animation>
             
                <Modal.Body>
                Thank you for submitting your application to vels university. We have successfully recieved all your materials.
                </Modal.Body>
                <Modal.Footer>
                    <Button className="bg-blue-400" onClick={() => setShowSuccessModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
                </form>
            </div >

        </div >
        </>
       
    )
})

export default ApplicationForm