import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Container, Row } from 'reactstrap';

const PaymentSuccessPage = () => {
    const navigate = useNavigate();
    const location = useLocation(); 

    const referenceNumber = sessionStorage.getItem("applicationId")

    useEffect(() => {
        if (location.pathname !== '/admission-form' || location.pathname !== '/payment-success') {
            sessionStorage.removeItem("applicationId")
        }
    })

    return (
        <>
            <Container>
                <Row className='pb-4'>
                    <h4 style={{ color: '#484f8d' }}>
                        Congratulations!
                    </h4>
                </Row>
                <Row>
                    <p>
                        Your application has been successfully submitted. We will now review your application and provide further communication. For more information kindly check you Email.
                    </p>
                    <p>
                        Your application reference number is : <b>{referenceNumber}</b>
                    </p>
                    <span className='text-center mt-4 mb-4'>
                        <Button
                            className='mx-3'
                            style={{ backgroundColor: '#2D4287', border: 'none' }}
                            onClick={() => navigate('/')}
                        >
                            Back to Home
                        </Button>
                    </span>
                </Row>
            </Container>



        </>
    );
};

export default PaymentSuccessPage;