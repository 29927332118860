import { getBusinessHeader } from '../../config/header';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { FormGroup, Input, Label } from 'reactstrap';
import { setUser } from '../../store/slices/user';
import { useNavigate } from 'react-router-dom';
import { Modal, ModalBody } from 'react-bootstrap';
import axios from 'axios';
import { useEffect, useState } from 'react';

const StudentForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showAlert, setShowAlert] = useState(false);

    const userSelector = useSelector(state => state.user.user);
    const [user, setUserDetail] = useState(null);
    console.log("userSelector", userSelector);
    const customerToken = sessionStorage.getItem("customerToken");
    const [application, setApplication] = useState(null)

    const formik = useFormik({
        initialValues: {
            applicationfee: null,
            admissionfee: null,
            twelfthMarksheet: null,
        },
        validationSchema: Yup.object({
            // Add validation schema here if needed
        }),
        onSubmit: async (values, helpers) => {
            try {
                // Upload files and get their URLs
                const twelfthMarksheetUrl = await uploadFile(values.twelfthMarksheet);
                const applicationFeeUrl = await uploadFile(values.applicationfee);
                const admissionFeeUrl = await uploadFile(values.admissionfee);

                // Prepare data for the application update
                const data = {
                    ...values,
                    twelfthMarksheet: twelfthMarksheetUrl,
                    applicationfee: applicationFeeUrl,
                    admissionfee: admissionFeeUrl,
                };

                // Determine the API endpoint URL
                const URL = user?.application_id
                    ? `/business/api/v1/application/${user.application_id}`
                    : '/business/api/v1/application';

                // Update the application
                const applicationResponse = user?.application_id
                    ? await axios.put(URL, data, getBusinessHeader(customerToken))
                    : await axios.post(URL, data, getBusinessHeader(customerToken));

                if (applicationResponse) {
                    toast.success('Application submitted successfully..!', { position: 'top-right' });
                    dispatch(setUser(applicationResponse.data));
                    sessionStorage.setItem('ApplicationData', JSON.stringify(applicationResponse.data));
                    setApplication(applicationResponse.data);
                    formik.resetForm();
                }
            } catch (err) {
                const errorMsg = err.response && err.response.data ? err.response.data.ErrorDetail : 'Application submission failed..!';
                toast.error(errorMsg, { position: 'top-right' });
                console.error('Error submitting form:', err);
                helpers.setSubmitting(false);

                if (err.response && err.response.status === 401) {
                    navigate('/login');
                }
            }
        },
    });

    useEffect(() => {
        setUserDetail(userSelector);

        const applicationData = JSON.parse(sessionStorage.getItem('ApplicationData'));
        console.log("mfererr", applicationData);
        if (applicationData) {
            formik.setValues({
                applicationfee: null,
                admissionfee: null,
                twelfthMarksheet: null,
            });
        }
    }, [userSelector]);

    const handleFileChange = (event, fieldName) => {
        formik.setFieldValue(fieldName, event.currentTarget.files[0]);
    };

    const uploadFile = async (file) => {
        if (!file) return null;

        const token = sessionStorage.getItem('customerToken');
        if (!token || !isValidToken(token)) {
            toast.error('Invalid or missing authentication token', { position: 'top-right' });
            navigate('/login');
            return null;
        }

        const response = await axios.get(`/business/api/v1/storage/signedurlput?file_folder=marksheets&file_extension=${file.type.split('/')[1]}`, getBusinessHeader(token));

        const fileKey = response.data.filekey;
        const signedURLPut = response.data.signed_url_put;

        await fetch(signedURLPut, {
            method: 'PUT',
            headers: {
                'Content-Type': file.type,
            },
            body: file,
        });

        return fileKey;
    };

    const isValidToken = (token) => {
        const parts = token.split('.');
        return parts.length === 3;
    };

    return (
        <>
            <div className='w-9/12 mx-5 border-1 rounded-md !border-richblack-200 px-1 py-1'>
                <div className='rounded-sm md:px-5 md:py-5 px-3 py-2'>
                    <h3 className='text-center text-4xl'>Uploads</h3>
                    <form className='StudentForm' onSubmit={formik.handleSubmit} noValidate>
                        <FormGroup>
                            <Label className="pb-2">12th Marksheet</Label>
                            <Input
                                type="file"
                                name="twelfthMarksheet"
                                id="twelfthMarksheet"
                                onChange={(event) => handleFileChange(event, 'twelfthMarksheet')}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label className="pb-2">Application fee screenshot</Label>
                            <Input
                                type="file"
                                name="applicationfee"
                                id="applicationfee"
                                onChange={(event) => handleFileChange(event, 'applicationfee')}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label className="pb-2">Admission fee screenshot</Label>
                            <Input
                                type="file"
                                name="admissionfee"
                                id="admissionfee"
                                onChange={(event) => handleFileChange(event, 'admissionfee')}
                            />
                        </FormGroup>

                        <Button className="bg-blue-500 text-richblack-5 text-[15px] !px-4 !py-2 rounded-md border-none" type='submit' disabled={formik.isSubmitting}>Save</Button>

                        <Modal
                            show={showAlert}
                            onHide={() => setShowAlert(false)}
                            backdrop="static"
                            keyboard={false}
                        >
                            <ModalBody className='text-center py-3'>
                                Are you sure you want to leave without paying? <br />
                                <div className="mt-2">
                                    <Button variant="secondary" onClick={() => setShowAlert(false)}>
                                        Close
                                    </Button>
                                    <span className="ml-2"></span>
                                    <Button variant="primary" onClick={() => navigate("/")}>
                                        Yes
                                    </Button>
                                </div>
                            </ModalBody>
                        </Modal>
                    </form>
                </div>
            </div>
        </>
    );
};

export default StudentForm;
