import React from 'react'
import ApplicationForm from '../components/admission/ApplicationForm'

const Admission = () => {
  return (
    <div className='pt-[50px] pb-[50px]'>
        <ApplicationForm/>
    </div>
  )
}

export default Admission