import React from 'react';
import '../App.css'; // Import your CSS file where you define styles
import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';

function Testimon() {
    return (
        <>
            <Typography variant='h4' style={{ paddingTop: '70px', paddingBottom:'25px', color:'white', textAlign:'center', fontWeight:'bolder' }} >WHY VELS?</Typography>
            <div className="card-container" style={{ width: "100%" }}>
                <Card sx={{ maxWidth: 365,height: '850px' ,backgroundColor:'white' }}>
                    <CardActionArea>
                        <CardMedia
                            style={{ padding: '20px', height: '200px' }}
                            component="img"
                            image="/assets/1.jpg"
                            alt="image1"
                        />
                        <CardContent>
                            <Typography gutterBottom  component="div" style={{fontSize:'25px',fontWeight:'bold', textAlign:'center'}} >
                                Comprehensive Education
                            </Typography>
                            <Typography variant="body1" color="text.secondary">
                                <p className='paragraph-test'>Our institute offers an extensive range of allied health sciences courses, built on exceptional infrastructure and rigorous academic standards that adhere to the highest medical protocols.</p>
                                <p className='paragraph-test'>Students have opportunities to specialize in various fields through targeted certificate courses and in-plant training, ensuring a well-rounded understanding of the allied health services field.</p>
                                <p className='paragraph-test'>Engaging in advanced coursework and hands-on training prepares students to excel in diagnostics, patient care, and healthcare management, equipping them to meet the evolving demands of the healthcare industry.</p>
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
                <Card sx={{ maxWidth: 365,height: '850px'  }}>
                    <CardActionArea>
                        <CardMedia
                            style={{ padding: '20px', height: '200px' }}
                            component="img"
                            image="/assets/2.jpg"
                            alt="image1"
                        />
                        <CardContent>
                            <Typography gutterBottom  component="div" style={{fontSize:'25px',fontWeight:'bold', textAlign:'center'}} >
                                Expert Faculty and Practical Training
                            </Typography>
                            <Typography variant="body1" color="text.secondary">
                                <p className='paragraph-test'>Our institute is backed by faculty with significant experience in healthcare, offering students practical, hands-on training within a dynamic learning environment. This prepares them for real-world challenges in hospitals, clinics, and specialized healthcare settings.</p>
                                <p className='paragraph-test'>Access to advanced laboratory facilities and specialized clinical training enhances the educational experience, enabling students to apply their knowledge in practical settings, from diagnostics labs to patient care.</p>
                                <p className='paragraph-test'>Under the guidance of professionals well-versed in the nuances of healthcare, our graduates are equipped to contribute effectively to health research, clinical operations, and the broader healthcare ecosystem, ensuring they are pivotal in advancing industry standards.</p>
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
                <Card sx={{ maxWidth: 365, height: '850px' }}>
                    <CardActionArea>
                        <CardMedia
                            style={{ padding: '20px', height: '200px' }}
                            component="img"
                            image="/assets/3.jpg"
                            alt="image1"
                        />
                        <CardContent>
                            <Typography gutterBottom component="div"  style={{fontSize:'25px',fontWeight:'bold',textAlign:'center'}} >
                            Industry Partnerships and Placement Opportunities
                            </Typography>
                            <Typography variant="body1" color="text.secondary">
                                <p className='paragraph-test'>Robust Industry Partnerships in Healthcare: At Vels University, we have cultivated strong partnerships within the healthcare industry, specifically tailored to allied health sciences. These collaborations enhance our curriculum and provide students with exceptional career opportunities.</p>
                                <p className='paragraph-test'>Expansive Job Networks and Placement Success: Our graduates gain access to a wide network of job opportunities, enabling them to secure prominent positions in leading hospitals, healthcare facilities, and corporations around the world.</p>
                                <p className='paragraph-test'>Proven Effectiveness of Educational Resources: The success of our students in the global healthcare market underscores the quality of our campus facilities and the extensive support provided through our job placement programs.</p>
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </div>
        </>

    );
}

export default Testimon;
