import { useMediaQuery } from '@mui/material';
import React from 'react';
import { FaGraduationCap } from 'react-icons/fa'; 

const AboutVels = () => {
    const isMobileAccordion = useMediaQuery('(max-width: 430px) and (max-height: 932px), (max-width: 412px) and (max-height: 915px), (max-width: 768px) and (max-height: 1024px)');

    return (
        <>
            <h2 className=" mb-4 text-center md:text-left" style={{ fontFamily:'sans-serif', fontWeight:'bolder',fontSize:isMobileAccordion?'22px':'33px'}}>
                Vels, The Best Allied Health Sciences College in Tamil Nadu.    </h2>
            <div className="container mx-auto flex items-center justify-center flex-col md:flex-row">

            <div className="md:w-1/2 flex justify-center mb-4 md:mb-0">
                    <img
                        src="/assets/image2.png" 
                        alt="College"
                        className="rounded-full md:h-auto"
                        style={{ maxWidth: '80%', height:isMobileAccordion? '250px':'auto',width:isMobileAccordion?'300px':'650px'}} 
                    />
                </div>
                <div className="md:w-1/2 md:pl-8">
                <div className="flex items-start mb-4">
                        <FaGraduationCap className="inline mr-2" style={{ color: '#420097', fontSize: '80px',marginTop:'-25px'}} />
                        <p className="text-lg  mb-4" style={{textAlign:'justify'}}>Dear Student, please welcome to Vels School of Allied Health Sciences, a component of VISTAS, has been a thriving academic institution since the founding of Vels Deemed University. Our department boasts a distinguished faculty with impressive credentials, including advanced degrees such as a Ph.D. and certifications such as SET and NET.</p>
                    </div>
                    <div className="flex items-start mb-4">

                    <FaGraduationCap className="inline mr-2" style={{ color: '#420097', fontSize: '50px',marginTop:'-11px'}} />
                    <p className="text-lg mb-4" style={{textAlign:'justify'}}>
                        Students studying allied health science courses like medical laboratory technology, cardiovascular technology and related fields benefit from a variety of activities aimed at developing critical abilities.
                    </p>
                    </div>
                    <div className="flex items-start mb-4">

                    <FaGraduationCap className="inline mr-2" style={{ color: '#420097', fontSize: '95px',marginTop:'-33px'}} />
                    <p className="text-lg mb-4" style={{textAlign:'justify'}}>
                        We broaden their skill set through diverse experience-based segments such as practical labs, seminars, conferences, hospital visits, guest lectures, internships, and collaborative projects. As a result of these focused initiatives, our students are well-prepared for professional careers, as evidenced by our high placement record with reputable healthcare institutions and organisations.
                    </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AboutVels;
