import React from 'react'
import ContactUsform from '../components/contactUs'

const ContactUs = () => {
  return (
    <div className='pt-[50px] pb-[50px]'>
        <ContactUsform/>

    </div>
  )
}

export default ContactUs