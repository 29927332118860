import React from 'react';

function TermsConditions() {
  return (
    <div style={{marginLeft:'400px',marginRight:'400px'}}>
      <h1 style={{fontWeight:'bolder',color:'#484f8d'}}>Terms & Conditions</h1>
      <br />
       <h6>Services overview</h6>
       <br />
      <p style={{color:'#707dd0'}}>
      
   
        As part of the registration process on this site, SREC may collect the following personally identifiable information about you: Name including first and last name, alternate email address, mobile phone number and contact details, postal code, demographic profile (like your age, gender, occupation, education, address, etc.) and information about the pages on the site you visit/access, the links you click on the site, the number of times you access the page and any such browsing information.
      </p>
      <h6>Eligibility</h6>
      <br />
      <p style={{color:'#707dd0'}}>
    Services of the site would be available to only select geographies in India.
        <br />
        Persons who are “incompetent to contract” within the meaning of the Indian Contract Act, 1872 including un-discharged insolvents, etc., are not eligible to use the site. If you are a minor i.e. under the age of 18 years but at least 13 years of age you may use the site only under the supervision of a parent or legal guardian who agrees to be bound by these Terms of Use. If your age is below 18 years, your parents or legal guardians can transact on behalf of you if they are registered users.
      </p>
      <h6>Account & Registration Obligations</h6>
      <br />
      <p style={{color:'#707dd0'}}>
    All shoppers have to register and login for placing orders on the site.
        <br />
        You have to keep your account and registration details current and correct for communications related to your purchases from the site.
      </p>
      <h6>Pricing</h6>
      <br />
      <p style={{color:'#707dd0'}}>
    All the products listed on the site will be sold at MRP unless otherwise specified.
        <br />
        The prices mentioned at the time of ordering are the prices charged on the date of delivery.
      </p>
    </div>
  );
}

export default TermsConditions;
