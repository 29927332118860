import axios from "axios";

//pass new generated access token here

let token = localStorage.getItem('clgComToken')

// apply base url for axios
//const API_URL = `${process.env.REACT_APP_BASE_PATH}/api/v1`;
const API_URL = `${process.env.REACT_APP_PROXY}/business/api/v1`

const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
);

export async function get(url, config = {}) {
  if (token == null) {
    token = localStorage.getItem('clgComToken')
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return axiosApi
    .get(url, { ...config })
    .then(response => response.data);
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, data , { ...config })
    .then(response => response.data);
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data);
}

export async function del(url, config = {}) {
  return axiosApi
    .delete(url, { ...config })
    .then(response => response.data);
}
