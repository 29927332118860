import React from 'react'
import PaymentSuccessPage from '../components/paymentSuccessPage'

const PaymentSuccess = () => {
  return (
    <div className='pt-[50px] md:!pb-[10.2%]'>
        <PaymentSuccessPage/>
    </div>
  )
}

export default PaymentSuccess