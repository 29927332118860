import { Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
    const navigate = useNavigate();

    // Define media query breakpoints
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const isMediumScreen = useMediaQuery('(max-width:960px)');

    return (
        <div className='bg-white h-full w-full'>
            <div className={`w-11/12 mx-auto flex ${isSmallScreen ? 'flex-col' : 'flex-row'} justify-between items-center`}>
                <div className={`flex ${isSmallScreen ? 'flex-col items-center' : 'items-center'}`}>
                    <img
                        className="cursor-pointer"
                        style={{
                            marginLeft: isSmallScreen ? '0' : '-100px',
                            width: isSmallScreen ? '380px' : isMediumScreen ? '300px' : '400px',
                            height: isSmallScreen ? '280px' : isMediumScreen ? '150px' : '200px'
                        }}
                        src={"/assets/velslogo.png"}
                        onClick={() => navigate('/')}
                        alt='VELS Logo'
                    />
                    <Typography
                        className="font-bold"
                        style={{
                            marginTop: isSmallScreen ? '-40px' : '-30px',
                            fontSize: isSmallScreen ? '30px' : isMediumScreen ? '35px' : '50px',
                            color: '#102368',
                            marginLeft: isSmallScreen ? '0' : '50px',
                            textAlign: isSmallScreen ? 'center' : 'left'
                        }}
                    >
                        VELS SCHOOL OF ALLIED HEALTH SCIENCES
                    </Typography>
                </div>
            </div>
        </div>
    );
};

export default Navbar;
