import { configureStore } from "@reduxjs/toolkit";
import { userSlice } from "./slices/user";

const reducer = {
  user: userSlice.reducer,
};

const store = configureStore({
  reducer: reducer,
  devTools: true,
});

export default store;
