import React from 'react'
import TermsCondition from '../components/TermsCondition'

const TermsAndCondition = () => {
  return (
    <div className='pt-[50px] pb-[50px]'>
        <TermsCondition/>

    </div>
  )
}

export default TermsAndCondition