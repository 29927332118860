import { Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import Navbar from './components/Navbar';
import Admission from './pages/Admission';
import Footer from './components/Footer';
import Heartbeat from './pages/Heartbeat';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProtectedRoute from './ProtectedRoute';
import TermsCondition from './pages/TermsAndCondition';
import ContactUs from './pages/ContactUs';
import PaymentSuccess from './pages/PaymentSuccess';
import StudentForm from './components/admission/StudentForm';
import { useSelector } from 'react-redux';
import Sidebar from './components/Sidebar';
import AdmissionPay from './components/admission/AdmissionPay.jsx';
import Apply from './components/admission/Apply.jsx'
function App() {
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);

  const location = useLocation();
  const showSidebar = location.pathname !== '/login'; 

  return (
    <div>
       {!isAuthenticated &&     <Navbar />}
       {showSidebar && isAuthenticated && <Sidebar/>}
  
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path="/admission-form" element={
          <ProtectedRoute>
            <Admission />
          </ProtectedRoute>}
        />
          <Route path="/admission-pay" element={
          <ProtectedRoute>
            <AdmissionPay />
          </ProtectedRoute>}
        />
           <Route path="/apply" element={
          <ProtectedRoute>
            <Apply/>
          </ProtectedRoute>}
        />
        <Route path="/heartbeat" element={<Heartbeat />} />
        <Route path="/terms-condition" element={<TermsCondition />} />

        <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/student-form" element={
          <ProtectedRoute>
            <StudentForm />
          </ProtectedRoute> 
        }/>
        <Route path="/payment-success" element={
          <ProtectedRoute>
            <PaymentSuccess />
          </ProtectedRoute> 
        }
        />

      </Routes>
      <ToastContainer />
      <Footer />
    </div>
  );
}

export default App;
