import React, { useState, useRef } from 'react';
import { Box, Tabs, Tab, Typography, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import StudentForm from './admission/StudentForm';
import Dashboard from './Dashboard';
import ApplicationForm from './admission/ApplicationForm';
import AdmissionPay from './admission/AdmissionPay.jsx';
import image from '../images/velslogo.png';
import { Input } from 'reactstrap';
import Apply from './admission/Apply.jsx';

function Logout() {
  const navigate = useNavigate();

  const handleLogout = () => {
    navigate('/');
  };

  return (
    <div className='w-11/12 mx-5 border-1 rounded-md !border-richblack-200 px-1 py-4'>
      <div className='rounded-sm md:px-5 md:py-5 px-3 py-2'>
        <Typography variant="body1">Are you sure you want to logout?</Typography>
        <Button className="bg-blue-400" style={{marginTop:'20px'}} onClick={handleLogout}>Logout</Button>
      </div>
    </div>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function VerticalTabs() {
  const [value, setValue] = useState(0);
  const [showApplicationForm, setShowApplicationForm] = useState(false);
  const isMobile = useMediaQuery('(max-width: 768px)');
  const currentUser = useSelector(state => state.user.user);
  const { name, is_application_saved, is_application_paid, is_admission_paid } = currentUser;
  


  const AppStatus = () => {
    if (is_application_paid) {
      return <span>Paid</span>;
    } else {
      return (
        <>
          <span>Not paid</span>
          <Button>Pay now</Button>
        </>
      );
    }
  };
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setShowApplicationForm(false);
  };

  const handleEditApplication = () => {
    setValue(1);
    setShowApplicationForm(true);
  };

  return (
    <>
      <Box sx={{ textAlign: 'center', padding: '20px' }}>
        <img src={image} alt="Sidebar" style={{ width: '200px', height: '100px' }} />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', height: '100%' }}>
        <Tabs
          orientation={isMobile ? 'horizontal' : 'vertical'}
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{
            flex: isMobile ? '0 1 auto' : '0 0 auto',
            borderRight: isMobile ? 'none' : 1,
            borderBottom: isMobile ? 1 : 'none',
            borderColor: 'divider',
            backgroundColor: 'black',
            width: isMobile ? '100%' : '300px',
            height: isMobile ? 'auto' : '1500px',
            overflowY: isMobile ? 'visible' : 'auto',
            '.MuiTab-root': {
              color: 'white',
            },
            '.Mui-selected': {
              color: 'black',
              backgroundColor: '#bcbcbc',
            },
          }}
        >
          <Tab label="Student Profile" />
          <Tab label="Application" />
          <Tab label="Admission" />
          {/* <Tab label="Upload" /> */}
          <Tab label="Logout" />
        </Tabs>

        <Box sx={{ flexGrow: 1 }}>
          <TabPanel value={value} index={0}>
            <Dashboard setActiveTab={setValue} />
          </TabPanel>
          <TabPanel value={value} index={1}>
      
            {/* {showApplicationForm ? (
              <ApplicationForm setActiveTab={setValue} />
            ) : (
              <>
                <Button onClick={handleEditApplication}>View Application</Button>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Typography style={{ padding: '15px' }}>Status :</Typography>
                    <AppStatus />
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Typography>Upload :</Typography>
                    <Input type='file' style={{ width: '300px' }} />
                    <Button>Save</Button>
                  </Box>
                </Box>
              </>
            )} */}
           <Apply
              isApplicationPaid={is_application_paid} 
              // handleApplicationPayment={handleApplicationPayment} 
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <AdmissionPay 
              isAdmissionPaid={is_admission_paid} 
              isApplicationPaid={is_application_paid} 
              // handleAdmissionPayment={handleAdmissionPayment} 
            />
          </TabPanel>
          {/* <TabPanel value={value} index={3}>
            <StudentForm />
          </TabPanel> */}
          <TabPanel value={value} index={3}>
            <Logout />
          </TabPanel>
        </Box>
      </Box>
    </>
  );
}

export default VerticalTabs;
