import React, { useEffect, useState } from 'react';
import Registerform from '../components/Registerform';
import Loginform from '../components/Loginform';
import AboutVels from '../components/Aboutvels';
import VerticalTabs from '../components/UgTabs';
import PgVerticalTabs from '../components/PgTabs';
import Testimon from '../components/Testimon';
import InstructionComponent from '../components/Instruction';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useMediaQuery } from '@mui/material';
import {post} from '../api/api_helper'

const Home = () => {
    const homesettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,
    };

    const [loginForm, setLoginForm] = useState(false);
    const [customerToken, setCustomerToken] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                let bodyData = new FormData();
                bodyData.append("grant_type", 'client_credentials');
                bodyData.append("client_id", process.env.REACT_APP_CLIENT_ID);
                bodyData.append("client_secret", process.env.REACT_APP_CLIENT_SECRET);
                const res = await post('/auth/token', bodyData);
                setCustomerToken(res.access_token);
                sessionStorage.setItem("customerToken", res.access_token);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);
    const isMobile = useMediaQuery('(max-width: 768px)'); return (
        <div>
            <div className={`backgroundbannerimage mx-auto ${isMobile ? 'mt-[20px]' : 'mt-[-40px]'} ${isMobile ? 'mb-[-10px]' : 'mb-[40px]'}`} style={{ height: isMobile ? '660px' : '660px' }}>
                {/* 20240613 Karthi, the Registration/Login form hided as requested */}
                {/* <div className='register-login-card'>
                    {loginForm ? (
                        <Loginform border="border" borderColor="border-richblack-800" btnClick={() => setLoginForm(false)} token={customerToken} />
                    ) : (
                        <Registerform border="border" borderColor="border-richblack-800" btnClick={() => setLoginForm(true)} token={customerToken} />
                    )}
                </div> */}
            </div>
            <div className='w-full bg-gray mx-auto pt-[100px]'>
                <div className='w-11/12 mx-auto'>
                    <AboutVels />
                </div>
            </div>
            <div className='w-full bg-gray mx-auto pt-[100px] mt-[25px]' style={{ backgroundColor: '#ebebeb' }}>
                <div className='w-9/12 mx-auto'>
                    <VerticalTabs token={customerToken} />
                </div>
            </div>
            <div className='w-full bg-gray mx-auto pt-[100px] mt-[25px]' style={{ backgroundColor: '#ebebeb' }}>
                <div className='w-9/12 mx-auto'>
                    <PgVerticalTabs token={customerToken} />
                </div>
            </div>
            <div className='background w-full mx-auto mt-[20px] mb-[100px]'>
                <div>
                    <Testimon />
                </div>
            </div>
            <div className='w-full bg-gray mx-auto'>
                <div className='w-11/12 mx-auto'>
                    <InstructionComponent />
                </div>
            </div>
        </div>
    );
}

export default Home;
