import { getBusinessHeader } from '../config/header'
import { get } from './api_helper'
import * as url from './constant'

//application
export const getApplication = ({ filter, skip, limit, sort, token }) => get(url.APPLICATION_URL + `?filter=${filter}&limit=${limit}&skip=${skip}&sort=${sort}`, getBusinessHeader(token))

export const getState = ({ filter, skip, limit, sort, token  }) => get(url.STATES_URL + `?filter=${filter}&limit=${limit}&skip=${skip}&sort=${sort}`, getBusinessHeader(token))

export const getCountry = ({ filter, skip, limit, sort, token  }) => get(url.COUNTRY_URL + `?filter=${filter}&limit=${limit}&skip=${skip}&sort=${sort}`, getBusinessHeader(token))

export const getDegree = ({ filter, skip, limit, sort, token  }) => get(url.DEGREE_URL + `?filter=${filter}&limit=${limit}&skip=${skip}&sort=${sort}`, getBusinessHeader(token))

export const getRegisters = ({ filter, skip, limit, sort, token }) => get(url.REGISTER_URL + `?filter=${filter}&limit=${limit}&skip=${skip}&sort=${sort}`, getBusinessHeader(token))

export const getCourse = ({ degreeId, filter, skip, limit, sort, token }) => get(url.DEGREE_URL + `/${degreeId}/courses?filter=${filter}&limit=${limit}&skip=${skip}&sort=${sort}`, getBusinessHeader(token))


