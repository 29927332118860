import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Box, Typography } from '@mui/material';
import { Input } from 'reactstrap';
import axios from 'axios';
import useRazorpay from 'react-razorpay';
import { getBusinessHeader, getImageHeader } from '../../config/header';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { setUser, setAuthenticate } from '../../store/slices/user';

function AdmissionPay({ isAdmissionPaid, is_application_paid }) {
    const [razorpayDetails, setRazorpayDetails] = useState(null);
    const [Razorpay] = useRazorpay();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [hidePayBtn, setHidePayBtn] = useState(false);
    const applicationAmount = '500';
    const [file, setFile] = useState(null);
    const [uploadedImageUrl, setUploadedImageUrl] = useState(null);
    const userSelector = useSelector(state => state.user.user);
    const [user, setUserDetail] = useState(null);

    useEffect(() => {
        setUserDetail(userSelector);
        if (userSelector && userSelector.admissionImage && userSelector.admissionImage.image_url) {
            setUploadedImageUrl(userSelector.admissionImage.image_url.split('?')[0]);
        }
    }, [userSelector]);

    const handlePayment = async () => {
        try {
            let bodyData = new FormData();
            bodyData.append("grant_type", 'client_credentials');
            bodyData.append("client_id", process.env.REACT_APP_CLIENT_ID);
            bodyData.append("client_secret", process.env.REACT_APP_CLIENT_SECRET);

            const tokenResponse = await axios.post('/business/api/v1/auth/token', bodyData);
            const customerToken = tokenResponse.data.access_token;

            const data = {
                payment_id: process.env.REACT_APP_PAYMENT_ID,
                amount: `${applicationAmount * 100}`
            };

            const response = await axios.post('/business/api/v1/sales/payments/razorpay', data, getBusinessHeader(customerToken));

            const options = {
                key: response.data.key_id,
                amount: response.data.amount,
                currency: response.data.currency,
                name: "Vels University",
                description: "Application Purchase Transaction",
                order_id: response.data.id,
                handler: function (response) {
                    setRazorpayDetails(response);
                }
            };

            const rzp1 = new Razorpay(options);

            rzp1.on("payment.failed", function (response) {
                alert(response.error.code);
                alert(response.error.description);
                alert(response.error.source);
                alert(response.error.step);
                alert(response.error.reason);
                alert(response.error.metadata.order_id);
                alert(response.error.metadata.payment_id);
            });

            rzp1.open();
        } catch (error) {
            if (error.response && error.response.status === 412) {
                toast.error('Error in payment settings please contact administrator.', { position: 'top-right' });
            } else {
                toast.error('An error occurred during payment initiation. Please try again.', { position: 'top-right' });
            }
            console.error('Error initiating payment:', error);
        }
    };

    const updateApplication = async () => {
        try {
            let bodyData = new FormData();
            bodyData.append("grant_type", 'client_credentials');
            bodyData.append("client_id", process.env.REACT_APP_CLIENT_ID);
            bodyData.append("client_secret", process.env.REACT_APP_CLIENT_SECRET);
            const tokenResponse = await axios.post('/business/api/v1/auth/token', bodyData);
            const customerToken = tokenResponse.data.access_token;

            const updateData = {
                razorpayDetails: razorpayDetails,
                is_application_paid: true,
                txn_date: new Date(),
                txn_amount: applicationAmount
            };
            const applicationId = user && user.application_id;
            const applicationUpdateResponse = await axios.put(`/business/api/v1/application/${applicationId}`, updateData, getBusinessHeader(`${customerToken}`));
            if (applicationUpdateResponse) {
                sessionStorage.setItem("applicationId", applicationId);
                dispatch(setUser(applicationUpdateResponse.data));
                toast.success('Payment successfully completed and Application updated!', { position: 'top-right' });
                setHidePayBtn(true);
                navigate('/payment-success', { replace: true });
                dispatch(setAuthenticate(true));
            }
        } catch (err) {
            console.log("err", err);
            toast.error('Payment failed..!', { position: 'top-right' });
        }
    };

    useEffect(() => {
        if (razorpayDetails) {
            updateApplication();
        }
    }, [razorpayDetails]);

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
        setUploadedImageUrl(URL.createObjectURL(selectedFile));
    };

    const uploadFile = async () => {
        if (!file) {
            toast.error('Please select a file to upload.', { position: 'top-right' });
            return;
        }

        try {
            const customerToken = sessionStorage.getItem("customerToken");
            const res = await axios.get(`/business/api/v1/storage/signedurlput?file_folder=admissionPayment&file_extension=${file.type.split('/')[1]}`, getBusinessHeader(customerToken));
            const fileKey = res.data.filekey;
            const signedURLPut = res.data.signed_url_put;

            await fetch(signedURLPut, {
                method: 'PUT',
                headers: getImageHeader(),
                body: file
            });

            const baseUrl = signedURLPut.split('?')[0];

            const updateData = {
                admissionImage: {
                    image_filekey: fileKey,
                    image_url: baseUrl
                },
                is_application_paid: false
            };

            const applicationId = user && user.application_id;
            await axios.put(`/business/api/v1/application/${applicationId}`, updateData, getBusinessHeader(`${customerToken}`));

            setUploadedImageUrl(baseUrl);
            toast.success("File uploaded successfully and application updated", { position: 'top-right' });
        } catch (error) {
            console.log(error);
            toast.error("Failed to upload file and update application", { position: 'top-right' });
        }
    };

    return (
        <div className='w-11/12 mx-5 border-1 rounded-md !border-richblack-200 px-5 py-4'>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Typography>Status:</Typography>
                    {user && (!user?.is_application_paid || user?.is_application_paid === false) ? (
                        <Typography style={{ backgroundColor: 'orange', padding: '5px' }}>Waiting list</Typography>
                    ) : (
                        <Typography style={{ backgroundColor: 'green', padding: '5px' }}>Selected</Typography>
                    )}
                    {user?.is_application_paid === true && (
                        <Button onClick={handlePayment} className="bg-blue-400">Pay Now</Button>
                    )}
                </Box>
                {!isAdmissionPaid && (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <Typography>Please Upload Payment Screenshot:</Typography>
                            <Input type='file' style={{ width: '300px' }} onChange={handleFileChange} />
                            <Button onClick={uploadFile} className="bg-blue-400">Save</Button>
                        </Box>
                        {/* {uploadedImageUrl && (
                            <Box sx={{ mt: 2 }}>
                                <Typography>Uploaded Image:</Typography>
                                <img src={uploadedImageUrl} alt="Uploaded Screenshot" style={{ maxWidth: '300px' }} />
                            </Box>
                        )} */}
                    </Box>
                )}
            </Box>
        </div>
    );
}

export default AdmissionPay;
