import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: { 
    value: { email: "", password: "" },
    user: null,
    isAuthenticated: false
},
  reducers: {
    login: (state, { payload }) => {
      state.value = payload;
    },
    logout: (state) => {
      state.value = { email: "", password: "" };
    },
    setLayout: (state, { payload }) => {
      state.layout = payload;
    },
    setUser: (state, { payload }) => {
        state.user = payload;
    },
    setAuthenticate: (state, { payload }) => {
      state.isAuthenticated = payload;
  },
  },
});

export const { login, logout, setLayout, setUser, setAuthenticate } = userSlice.actions;
export default userSlice.reducer;
