import React from 'react';
import { Typography, Grid, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { FaGraduationCap } from 'react-icons/fa';
import step from '../images/1.jpg';
import '../App.css'; 
import { Container } from 'reactstrap';

function InstructionComponent() {
    return (
        <Container style={{display:'flex', flexDirection: 'column', alignItems:'center'}}>
            {/* Centered Heading */}
            <Typography variant="h4" gutterBottom className="instruction-heading" style={{ fontWeight: 'bolder'}}>
                Instructions
            </Typography>

            {/* Grid for Instructions and Image */}
             <Grid container spacing={2} width={'90%'} mx={3} className="instruction-grid"  style={{display: 'flex', flexDirection:'row', alignItems:'center', gap:'100px'}}>   {/* className="instruction-grid" justifyContent="center" */}
                {/* Left side: Paragraphs with graduation hat icons as bullet points */}
                <Grid item xs={12} md={5} >
                    <List>
                        <ListItem disablePadding style={{ textAlign: 'justify', marginBottom: '20px', display:'flex', flexDirection:'row', alignItems:'start' }}>
                            <ListItemIcon style={{ marginRight: '12px' }}>
                                <FaGraduationCap className='instruction-icon' fontSize={25} style={{ color: '#420097', marginLeft:'35px', marginTop:'3px' }} />
                            </ListItemIcon>
                            <ListItemText className='instruction-text' primary="The online application is for admission to Allied Health Science programmes offered in Vels Deemed University Campus, Chennai." />
                        </ListItem>
                        <ListItem disablePadding style={{ textAlign: 'justify', marginBottom: '20px', display:'flex', flexDirection:'row', alignItems:'start' }}>
                            <ListItemIcon style={{ marginRight: '12px' }}>
                                <FaGraduationCap className='instruction-icon' fontSize={25} style={{ color: '#420097', marginLeft:'35px', marginTop:'3px' }} />
                            </ListItemIcon>
                            <ListItemText className='instruction-text' primary="Admission is based on the merit and marks obtained in the qualifying examination." />
                        </ListItem>
                        <ListItem disablePadding style={{ textAlign: 'justify', marginBottom: '20px', display:'flex', flexDirection:'row', alignItems:'start' }}>
                            <ListItemIcon style={{ marginRight: '12px' }}>
                                <FaGraduationCap className='instruction-icon' fontSize={25} style={{ color: '#420097', marginLeft:'35px', marginTop:'3px' }} />
                            </ListItemIcon>
                            <ListItemText className='instruction-text' primary="Email ID submitted at the time of registration will be used for all correspondences until enrolment is completed. Change in Email ID will NOT be permitted under any circumstances." />
                        </ListItem>
                    </List>
                </Grid>
                {/* Right side: Fixed image */}
                <Grid item xs={12} md={4} style={{ textAlign: 'center' }}>
                    <img src={step} alt="steps" className="instruction-image" />
                </Grid>
            </Grid>
        </Container>
    );
}

export default InstructionComponent;