const getHeader = (token) => {
    const header = {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0'
        }
    }
    return header
}

const getBusinessHeader = (token) => {
    const header = {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0'
        }
    }
    return header
}

const getImageHeader = () => {
    // { 'Content-Type': 'image/png' }
    const myHeaders = new Headers()
    myHeaders.append('Access-Control-Allow-Origin', '*')
    return myHeaders
}

export { getHeader, getBusinessHeader, getImageHeader }