import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Button, useMediaQuery, useTheme, Accordion, AccordionSummary, AccordionDetails, Tab, Tabs } from '@mui/material'; // Import Accordion components

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'; // Import expand icon
import { getCourse, getDegree } from '../api';

function TabPanel(props) {
    const { children, value, course, index, token, ...other } = props;

    const handleApplyNow = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3, backgroundColor: 'white', height: '900px', textAlign:'justify' }}>
                    <Typography variant="body1" pb={3} dangerouslySetInnerHTML={{ __html: course?.course_description }}></Typography>
                    <Button variant="contained" style={{backgroundColor:'#34258d'}} onClick={handleApplyNow}>
                        Apply Now
                    </Button>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export default function VerticalTabs({ token }) {
    const [value, setValue] = useState(0);
    const theme = useTheme();
    const isMobileAccordion = useMediaQuery('(max-width: 430px) and (max-height: 932px), (max-width: 412px) and (max-height: 915px), (max-width: 768px) and (max-height: 1024px)');
    const isMobileCentered = useMediaQuery('(max-width: 768px)');

    const handleChange = (index) => {
        setValue(value === index ? -1 : index);
    };
    const [degreeId, setDegreeId] = useState('');
    const [courses, setCourses] = useState([]);
    const [degree, setDegree] = useState([]);
    const commonToken = token;
    useEffect(() => {
        getDegreeData();
    }, [token]);

    const getDegreeData = async () => {
        try {
            const degreeRes = await getDegree({ limit: 0, skip: 0, sort: '{"display_order": 1}', filter: '', token });
            const bscDegrees = degreeRes.result.filter((degree) => degree.degree_name === 'B.Sc');
            if (bscDegrees.length > 0) {
                const bscDegreeId = bscDegrees[0].degree_id;
                setDegree(bscDegreeId);
                getCoursedata(bscDegreeId);
            }
        } catch (error) {
            console.error('Error fetching degree data:', error);
        }
    };

    const getCoursedata = async (degreeId) => {
        try {
            const courseRes = await getCourse({ degreeId, limit: 0, skip: 0, sort: '{"display_order": 1}', filter: '', token });
            if (courseRes) {
                setCourses(courseRes.result);
            }
        } catch (error) {
            console.error('Error fetching course data:', error);
        }
    };
    const handleApplyNow = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    return (
        <>
            <Typography
                variant='h5'
                fontWeight='bold'
                style={{
                    marginTop: '-50px',
                    paddingBottom: '15px',
                    textAlign: 'center',
                    fontSize: isMobileAccordion ? '22px' : '30px'
                }}
            >
                ALLIED HEALTH SCIENCES - UG COURSES
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: isMobileCentered ? 'center' : 'center',
                    alignItems: 'center',
                    height: 'auto',
                    width: '100%',

                }}
            >
                <Box sx={{ width: isMobileAccordion ? '100%' : '90%', display: 'flex', p: isMobileAccordion ? 0 : 3, borderRadius: 4, mb: 6 }} style={{ backgroundColor: '#ebebeb' }}>
                    {isMobileAccordion ? (
                        <Box sx={{ width: '100%' }}>
                            {courses.map((course, index) => (
                                <Accordion key={index} expanded={value === index} onChange={() => handleChange(index)} >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon style={{ color: value === index ? 'white' : 'inherit' }} />}
                                        aria-controls={`panel${index}bh-content`}
                                        id={`panel${index}bh-header`}
                                        sx={{
                                            backgroundColor: value === index ? '#57009b' : 'inherit',
                                            color: value === index ? 'white' : 'inherit',
                                        }}
                                    >
                                        <Typography>{course.title}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography variant="body1" pb={3} dangerouslySetInnerHTML={{ __html: course.course_description }}></Typography>
                                    </AccordionDetails>
                                    <Button variant="contained" color="primary" onClick={handleApplyNow} style={{ marginLeft: '20px', marginBottom: '20px' }}>
                                        Apply Now
                                    </Button>
                                </Accordion>
                            ))}
                        </Box>
                    ) : (
                        <>
                            <Tabs
                                orientation="vertical"
                                // variant="scrollable"
                                value={value}
                                onChange={(event, newValue) => setValue(newValue)}
                                aria-label="Vertical tabs example"
                                sx={{ flex: '0 0 auto', borderRight: 1, borderColor: 'divider' }}
                            >
                                {courses.map((course, index) => (
                                    <Tab
                                        key={index}
                                        label={course.title}
                                        {...a11yProps(index)}
                                        sx={{
                                            '&.Mui-selected': { backgroundColor: '#420097', color: 'white', border: 'none' },
                                            fontWeight: 'bolder',
                                            color: 'black',
                                            justifyContent: 'left',
                                            alignItems: 'left',
                                            textAlign: 'left',
                                            padding: '25px',
                                            width: '480px',
                                            border: '1px solid grey',
                                            backgroundColor: 'white'
                                        }}
                                    />
                                ))}
                            </Tabs>
                            <Box sx={{ flex: '1', overflow: 'auto' }}>
                                {courses.map((course, index) => (
                                    <TabPanel key={index} value={value} index={index} course={course}>
                                        <Card sx={{ minWidth: 900, maxWidth: 'auto' }} style={{ boxShadow: 'none' }}>
                                            <CardContent>
                                                <Typography variant="body1" pb={3} dangerouslySetInnerHTML={{ __html: course.course_description }}></Typography>
                                            </CardContent>
                                        </Card>
                                    </TabPanel>
                                ))}
                            </Box>
                        </>
                    )}
                </Box>
            </Box>
        </>
    );
}