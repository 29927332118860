import { Typography, Link } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Dashboard = ({ setActiveTab }) => {
  const currentUser = useSelector(state => state.user.user);
  const { name, is_application_saved, is_application_paid, is_admission_paid } = currentUser;

  const handleLinkClick = (event, tabIndex) => {
    event.preventDefault();
    setActiveTab(tabIndex);
  };

  const renderApplicationLink = () => {
    if (!is_application_saved) {
      return <Link href="#" onClick={(e) => handleLinkClick(e, 1)}>Submit Now</Link>;
    } else if (!is_application_paid || is_application_paid === false) {
      return <Link href="#" onClick={(e) => handleLinkClick(e, 1)}>Pay Now</Link>;
    } else {
      return <Link href="#" onClick={(e) => handleLinkClick(e, 3)}>Upload Screenshot</Link>;
    }
  };

  const renderAdmissionLink = () => {
    if (!is_admission_paid) {
      return <span style={{ paddingLeft: '30px' }}>   -   </span>;
    }
    else if (is_application_paid === true) {
      return <span>Selected - <Link href="#" onClick={(e) => handleLinkClick(e, 2)}>Pay Now</Link></span>;
    } else {
      return <span>Paid - <Link href="#" onClick={(e) => handleLinkClick(e, 3)}>Upload Screenshot</Link></span>;
    }
  };

  return (
    <>
      <div className='w-11/12 mx-auto rounded-md  border-1 rounded-md !border-grey px-1 py-1'>
        <div className='rounded-sm  md:px-5 md:py-5 px-5 py-5' >
          <h4 style={{textAlign:'center'}}>Dear {name.toUpperCase()} </h4>
          <Typography>

            Welcome to the Vels Schools of Allied Health Sciences program. We are thrilled to have you join our community of dedicated and passionate students.
            As you embark on this exciting journey, you'll be part of a dynamic and supportive environment where you can develop your skills,
            knowledge, and expertise in the field of allied health sciences.
            We look forward to seeing all that you will accomplish and the positive impact you will make in the field of allied health.
            Welcome to the start of an exciting and rewarding journey!</Typography>
          {/* <Typography variant='h6' style={{padding:'10px'}}> Application Status : {renderApplicationLink()}</Typography>
          <Typography variant='h6' style={{padding:'10px'}}> Admission Status : {renderAdmissionLink()}</Typography> */}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
