import React from 'react';
import { Typography, Grid, List, ListItem, ListItemText, ListItemIcon, Divider } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import TimerIcon from '@mui/icons-material/Timer';

const Footer = () => {
    return (
        <div style={{ backgroundColor: '#f5f5f5', padding: '20px' }}>
            <div className='mx-auto max-w-6xl' style={{ marginLeft:'10px'}}>
                <Typography variant="h5" gutterBottom style={{ fontWeight: 'bolder', fontSize: "30px", marginBottom: '1rem',marginLeft:'20px'}}>
                    Contact
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <List>
                            <ListItem disablePadding style={{ marginBottom: '1rem', display: 'flex', flexDirection: 'row', alignItems: 'start' }}>
                                <ListItemIcon>
                                    <SupportAgentIcon style={{ color: '#1e81b0', marginLeft: 'auto', marginTop: '5px' }} />
                                </ListItemIcon>
                                <ListItemText
                                    sx={{ paddingLeft: '10px' }}
                                    primary={<Typography variant="body1" style={{ fontWeight: 'bold', paddingBottom: '10px' }}>Help Desk</Typography>}
                                    secondary={<Typography variant="body1" style={{ color: '#383838' }}>+917550268063</Typography>}
                                />
                            </ListItem>
                            <Divider variant="middle" sx={{ backgroundColor: '#000000', marginY: '20px', height:'1px' }} />
                            <ListItem disablePadding style={{ marginBottom: '1rem', display: 'flex', flexDirection: 'row', alignItems: 'start' }}>
                                <ListItemIcon>
                                    <TimerIcon style={{ color: '#1e81b0', marginLeft: 'auto', marginTop: '5px' }} />
                                </ListItemIcon>
                                <ListItemText
                                    sx={{ paddingLeft: '10px' }}
                                    primary={<Typography variant="body1" style={{ fontWeight: 'bold', paddingBottom: '10px' }}>Help Desk Hours</Typography>}
                                    secondary={<Typography variant="body1" style={{ color: '#383838' }}>Mon - Sat (except public holidays) 9:00 AM to 5:00 PM</Typography>}
                                />
                            </ListItem>
                            <Divider variant="middle" sx={{ backgroundColor: '#000000', marginY: '20px', height:'1px' }} />
                            <ListItem disablePadding style={{ marginBottom: '1rem', display: 'flex', flexDirection: 'row', alignItems: 'start' }}>
                                <ListItemIcon>
                                    <EmailIcon style={{ color: '#1e81b0', marginLeft: 'auto', marginTop: '5px' }} />
                                </ListItemIcon>
                                <ListItemText
                                    sx={{ paddingLeft: '10px' }}
                                    primary={<Typography variant="body1" style={{ fontWeight: 'bold', paddingBottom: '10px' }}>Email</Typography>}
                                    secondary={<Typography variant="body1" style={{ color: '#383838' }}>admissions@ahsveluniv.com</Typography>}
                                />
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3916.1876710831515!2d80.1598562!3d12.9575528!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525e43ecc3adf7%3A0xa0ef3f153468699c!2sVels%20Institute%20of%20Science%2C%20Technology%20%26%20Advanced%20Studies%20(VISTAS)!5e0!3m2!1sen!2sin!4v1687858057023!5m2!1sen!2sin"
                            width="100%"
                            height="400"
                            
                            style={{ border: 0,marginBottom:'30px' }}
                            allowFullScreen=""
                            loading="lazy"
                            title="Vels Institute Location"
                        ></iframe>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}

export default Footer;
